import { gql } from '@apollo/client';
import Head from 'next/head';
import React, { useState } from 'react';
import BlogContent, { blogPostsPerPage } from '~/components/_blog/BlogContent';
import type {
  getAllBlogCategories,
  getAllBlogCategories_allBlogCategories,
} from '~/types/generated/cms/getAllBlogCategories';
import type {
  getAllBlogPostSlugsWithPagination,
  getAllBlogPostSlugsWithPagination_allBlogPosts,
  getAllBlogPostSlugsWithPaginationVariables,
} from '~/types/generated/cms/getAllBlogPostSlugsWithPagination';
import type {
  getAllBlogPostsWithPagination,
  getAllBlogPostsWithPagination_allBlogPosts,
  getAllBlogPostsWithPaginationVariables,
} from '~/types/generated/cms/getAllBlogPostsWithPagination';
import type { getAllTags, getAllTags_allBlogTags } from '~/types/generated/cms/getAllTags';
import type {
  spotlightBlogPosts,
  spotlightBlogPosts_allBlogPosts,
} from '~/types/generated/cms/spotlightBlogPosts';
import { BlogPostFragment, BlogTagFragment } from 'src/datoCMS/fragments/fragments';
import { getDatoClient } from 'src/datoCMS/datoCmsClient';
import type { GetStaticProps } from 'next/types';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const client = getDatoClient();

const GET_SPOTLIGHT_BLOG_POSTS = gql`
  ${BlogPostFragment}
  query spotlightBlogPosts {
    allBlogPosts(filter: { spotlightPosition: { neq: "" } }) {
      ...BlogPostFragment
    }
  }
`;

export const GET_ALL_TAGS = gql`
  ${BlogTagFragment}
  query getAllTags {
    allBlogTags {
      ...BlogTagFragment
    }
  }
`;

export const GET_ALL_BLOG_CATEGORIES = gql`
  query getAllBlogCategories {
    allBlogCategories {
      id
      name
      slug
      isHighlighted
    }
  }
`;

const GET_ALL_BLOG_POSTS_WITH_PAGINATION = gql`
  ${BlogPostFragment}
  query getAllBlogPostsWithPagination($first: IntType!, $skip: IntType!) {
    allBlogPosts(
      filter: { spotlightPosition: { eq: "" } }
      first: $first
      skip: $skip
      orderBy: firstPublishedAt_DESC
    ) {
      ...BlogPostFragment
    }
    _allBlogPostsMeta(filter: { spotlightPosition: { eq: "" } }) {
      count
    }
  }
`;

const GET_ALL_BLOG_POST_SLUGS_WITH_PAGINATION = gql`
  query getAllBlogPostSlugsWithPagination($first: IntType!, $skip: IntType!) {
    allBlogPosts(first: $first, skip: $skip) {
      id
      slug
    }
    _allBlogPostsMeta {
      count
    }
  }
`;

export const allTags = async (client: ApolloClient<NormalizedCacheObject>) => {
  const { data: allTagsData } = await client.query<getAllTags>({ query: GET_ALL_TAGS });

  return allTagsData.allBlogTags;
};

export const allBlogCategories = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<readonly getAllBlogCategories_allBlogCategories[]> => {
  const { data } = await client.query<getAllBlogCategories>({ query: GET_ALL_BLOG_CATEGORIES });

  return data.allBlogCategories;
};

const getSpotlightBlogPosts = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<readonly spotlightBlogPosts_allBlogPosts[]> => {
  const { data } = await client.query<spotlightBlogPosts>({
    query: GET_SPOTLIGHT_BLOG_POSTS,
  });

  return data.allBlogPosts;
};

const allBlogPostsWithPagination = async (skip: number) => {
  const { data } = await client.query<
    getAllBlogPostsWithPagination,
    getAllBlogPostsWithPaginationVariables
  >({
    query: GET_ALL_BLOG_POSTS_WITH_PAGINATION,
    variables: { first: blogPostsPerPage, skip },
  });

  return data;
};

const allBlogPostSlugsWithPagination = async (skip: number) => {
  const { data } = await client.query<
    getAllBlogPostSlugsWithPagination,
    getAllBlogPostSlugsWithPaginationVariables
  >({
    query: GET_ALL_BLOG_POST_SLUGS_WITH_PAGINATION,
    variables: { first: 100, skip },
  });

  return data;
};

const calculatePagesCount = (pageSize: number, totalCount: number) => {
  // we suppose that if we have 0 items we want 1 empty page
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

export const allBlogPostSlugs = async () => {
  const {
    _allBlogPostsMeta: { count: totalBlogPosts },
  } = await allBlogPostSlugsWithPagination(0);

  let posts: readonly getAllBlogPostSlugsWithPagination_allBlogPosts[] = [];
  let page = 0;

  const totalPages = calculatePagesCount(100, totalBlogPosts);

  while (page < totalPages) {
    const { allBlogPosts } = await allBlogPostSlugsWithPagination(page * 100);

    posts = [...posts, ...allBlogPosts];
    page++;
  }

  return posts;
};

interface PageProps {
  posts: readonly getAllBlogPostsWithPagination_allBlogPosts[];
  postCount: number;
  spotlightPosts: readonly spotlightBlogPosts_allBlogPosts[];
  tags: readonly getAllTags_allBlogTags[];
  categories: readonly getAllBlogCategories_allBlogCategories[];
}

const homePageMetaData = {
  title: 'Blog | Energiebespaarders',
  description:
    'Blijf op de hoogte van nieuws over verduurzaming van woningen en ontwikkelingen in de energiemarkt. Ook geven we tips en advies rondom energiebesparing, bijvoorbeeld over financiering en subsidies.',
};

const BlogPage: React.FC<PageProps> = ({ posts, tags, categories, spotlightPosts, postCount }) => {
  const [allPosts, setAllPosts] = useState(posts);

  const fetchNextPosts = async (page: number) => {
    const skip = page * blogPostsPerPage;
    const { allBlogPosts: nextPosts } = await allBlogPostsWithPagination(skip);

    setAllPosts([...allPosts, ...nextPosts]);
  };

  return (
    <>
      <Head>
        <title key="title">{homePageMetaData.title}</title>
        <meta name="description" key="meta-description" content={homePageMetaData.description!} />
      </Head>
      <BlogContent
        fetchNextPosts={fetchNextPosts}
        heading={{
          heading: { text: 'Alle recente artikelen', as: 'h2' },
        }}
        tags={tags}
        posts={allPosts}
        postsCount={postCount}
        spotlightPosts={spotlightPosts}
        categories={categories}
        showSpotlightPosts
      />
    </>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async ({ preview }) => {
  const client = getDatoClient(preview);

  const {
    allBlogPosts,
    _allBlogPostsMeta: { count },
  } = await allBlogPostsWithPagination(0);

  return {
    props: {
      posts: allBlogPosts,
      postCount: count,
      spotlightPosts: await getSpotlightBlogPosts(client),
      tags: await allTags(client),
      categories: await allBlogCategories(client),
    },
    revalidate: 60,
  };
};

export default BlogPage;
